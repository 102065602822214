
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'

@Component
export default class TotalInteractionsChart extends Vue {
  @Prop({ required: true }) from!: Date;
  @Prop({ required: true }) to!: Date;

  loading = true;

  interactions: Record<string, any[]> = {}

  chartOptions = {
    chart: {
      id: 'vistors'
    },
    colors: ['#040f49', '#1DA1F2', '#0866ff'],
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
    },
    dataLabels: {
      enabled: false
    },
  }

  series = [] as any[]


  @Watch('from', { immediate: true })
  @Watch('to')
  async fetchInteractions() {
    this.loading = true;
    this.interactions = await StatServices.ListInteractions(
      this.from,
      this.to
    );

    let otherCliks = [] as any[];
    let twitterClicks = [] as any[];
    let facebookClicks = [] as any[]; 

    for (let date in this.interactions) {
      let interactions = this.interactions[date];
      twitterClicks.push([new Date(date).getTime(), interactions.filter((e: any) => e.type === 9).length])
      otherCliks.push([new Date(date).getTime(), interactions.filter((e: any) => e.type === 7).length])
      facebookClicks.push([new Date(date).getTime(), interactions.filter((e: any) => e.type === 8).length])
    }


    otherCliks.sort((a, b) => a[0] - b[0])
    twitterClicks.sort((a, b) => a[0] - b[0])
    facebookClicks.sort((a, b) => a[0] - b[0])

    this.series = [
      {
        name: 'Andere sociale media',
        data: otherCliks,
      },
      {
        name: 'X',
        data: twitterClicks
      },
      {
        name: 'Facebook',
        data: facebookClicks
      }
    ]

    console.log('series')
    console.log(this.series)

    this.loading = false;
  }
}
